@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.modify-btn {
  &.v-btn {
    position: absolute;
    z-index: 1;
    top: var(--box-padding-dashboard);
    right: var(--box-padding-dashboard);
    min-width: 0 !important;
    padding: 0 !important;
  }
}

.white-background .v-input__control .v-input__slot,
.white-background .v-text-field__suffix {
  color: inherit !important;
  background-color: transparent !important;
}

.large-text .v-input__control input {
  font-size: 2.5rem;
  font-weight: 700;
}

.unit {
  font-size: 2.5rem;
  font-weight: 700;
}
